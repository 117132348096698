<script setup lang="ts">
import { useForm } from 'vee-validate';
import * as yup from 'yup';
// Stores
const { currentSite } = storeToRefs(adminStore());

// Composables
const { t } = useI18n();
const { updateSite } = useSiteApi();
const { daysListAsOptions } = useConstants();
const { handleSubmit, meta, resetForm } = useForm({
  validationSchema: yup.object({
    name: yup.string().required(t('global.field_required')),
    production_start_weekday: yup.string().required(t('global.field_required')),
  }),
});

// Data
const loading = ref(false);

// Methods
const submit = handleSubmit((values) => {
  loading.value = true;
  updateSite(currentSite.value?.id, values);
  loading.value = false;
});

onMounted(() => {
  resetForm({
    values: {
      name: currentSite.value?.name,
      production_start_weekday: currentSite.value?.production_start_weekday,
    },
  });
});
</script>
<template>
  <ui-card :title="$t('global.informations')">
    <div class="grid grid-cols-2 gap-4">
      <ui-form-input-text name="name" class="col-span-1" :label="$t('global.name')" :placeholder="$t('global.name')" />
      <nrjx-input-select
        name="production_start_weekday"
        class="col-span-1"
        :label="$t('global.production_start_week_day')"
        :placeholder="$t('global.production_start_week_day')"
        item-key="key"
        item-label="label"
        fit-to-select
        :items="daysListAsOptions"
      />
    </div>

    <template #footer>
      <ui-button :loading="loading" :color="meta.dirty ? 'primary' : 'disabled'" @click="submit()">{{ $t('global.save') }}</ui-button>
    </template>
  </ui-card>
</template>
