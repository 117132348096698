<script setup lang="ts">
import { useForm } from 'vee-validate';
import * as yup from 'yup';

// Stores
const { currentSite } = storeToRefs(adminStore());

// Composables
const { t } = useI18n();
const { createReference, updateReference } = useSiteApi();
const { handleSubmit, meta, resetForm } = useForm({
  validationSchema: yup.object({
    cost: yup.number().required(t('global.field_required')).typeError(t('global.field_required')),
    co2: yup.number().required(t('global.field_required')).typeError(t('global.field_required')),
    consumption: yup.number().required(t('global.field_required')).typeError(t('global.field_required')),
    production: yup.number().typeError(t('global.field_required')),
    deployment_date: yup.date().required(t('global.field_required')).typeError(t('global.field_required')),
  }),
});

// Data
const loading = ref(false);

// Methods
const submit = handleSubmit((values) => {
  loading.value = true;
  if (currentSite.value?.consumption_reference?.id) {
    updateReference(currentSite.value?.consumption_reference?.id, { ...values, site_id: currentSite.value?.id });
  } else {
    createReference({ ...values, site_id: currentSite.value?.id });
  }
  loading.value = false;
});

onMounted(() => {
  resetForm({
    values: {
      cost: currentSite.value?.consumption_reference?.cost,
      co2: currentSite.value?.consumption_reference?.co2,
      consumption: currentSite.value?.consumption_reference?.consumption,
      production: currentSite.value?.consumption_reference?.production,
      deployment_date: currentSite.value?.consumption_reference?.deployment_date,
    },
  });
});
</script>
<template>
  <ui-card :title="$t('admin.reference_consumption')">
    <div class="grid grid-cols-2 gap-x-4">
      <ui-form-input-text
        name="cost"
        class="col-span-1"
        type="number"
        :label="$t('admin.reference_cost')"
        :placeholder="$t('admin.reference_cost')"
        right-icon="Euro"
        required
      />
      <ui-form-input-text
        name="co2"
        class="col-span-1"
        type="number"
        :label="$t('admin.reference_co2')"
        right-text="g/CO2"
        :placeholder="$t('admin.reference_co2')"
        required
      />
      <ui-form-input-text
        name="consumption"
        class="col-span-1"
        type="number"
        :label="$t('admin.reference_consumption')"
        :placeholder="$t('admin.reference_consumption')"
        right-text="kWh"
        required
      />
      <ui-form-input-text
        name="production"
        class="col-span-1"
        type="number"
        :label="$t('admin.reference_production')"
        :placeholder="$t('admin.reference_production')"
        right-text="kg"
      />
      <ui-form-input-date-new
        name="deployment_date"
        class="col-span-1"
        type="date"
        :label="$t('admin.deployement_date')"
        :placeholder="$t('admin.deployement_date')"
        required
      />
    </div>

    <template #footer>
      <ui-button :loading="loading" :color="meta.dirty ? 'primary' : 'disabled'" @click="submit()">{{ $t('global.save') }}</ui-button>
    </template>
  </ui-card>
</template>
